<template>
  <card
    ref="card"
    actions
    block
    :title="$tc('invoice')"
    :loading="loading"
    @reload="fetch"
  >
    <template #body>
      <data-table
        :data="invoices"
        :pages="pages"
        :columns="[
          'id',
          {
            key: 'total',
            label: 'value',
            filters: ['currency'],
          },
          {
            key: 'status',
            class: 'badge badge-primary',
            localize: true,
          },
        ]"
        @change-page="fetch"
      >
        <template #actions="item">
          <a :href="item.url">
            <i class="fas fa-eye"></i>
          </a>
        </template>
      </data-table>
    </template>
  </card>
</template>

<script>
// @ is an alias to /src
import Card from '@/components/rebranding/Card.vue';
import DataTable from '@/components/rebranding/DataTable.vue';

export default {
  name: 'InvoicesCard',
  components: {
    Card,
    DataTable,
  },
  data() {
    return {
      loading: true,
      invoices: [],
      page: 1,
      pages: 1,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store.dispatch('fetchInvoices', { page }).then((response) => {
        this.invoices = response.data.data;
        this.$emit('fetched', response.data.data);
        this.pages = response.data.last_page;
        this.loading = false;
      });
    },
  },
};
</script>
