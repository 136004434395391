<template>
  <div class="content">
    <PageHeader :title="$t('invoices.header')" />
    <div class="page-content container-fluid">
      <div class="row infos-wrapper" style="gap: 50px; justify-content: center">
        <div class="card-deck" style="width: 100%; margin-bottom: 2rem">
          <card class="card bg-balance text-center" no-header>
            <template #body>
              <h4 class="card-title text-white">
                {{ $t('home.basic-info.lbl-balance') }}
              </h4>
              <p class="card-text text-white">
                {{ account.balance | currency }}
              </p>
            </template>
          </card>
          <!-- Agentes Logados -->
          <!-- <div class="card agentes-card">
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-md-2"
                  style="display: flex; align-items: center"
                >
                  <div class="">
                    <img
                      class="icon_title"
                      style="transform: scale(1.2)"
                      src="@/assets/icons/telemarketer.svg"
                      alt="Telemarketer Icon"
                    />
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="infos-desc">
                    <strong class="title">{{ $t('users.logged-in') }}</strong>
                    <div class="w-100"></div>
                    <p class="mb-0 text-yup">25</p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!--/Agentes Logados -->
          <!-- Faturas em aberto -->
          <!-- <div class="card invoices-card">
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-md-2"
                  style="display: flex; align-items: center"
                >
                  <div class="">
                    <img
                      class="icon_title"
                      style="transform: scale(1.2)"
                      src="@/assets/icons/invoices.svg"
                      alt="Telemarketer Icon"
                    />
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="infos-desc">
                    <strong class="title">{{
                      $t('users.open-invoices')
                    }}</strong>
                    <div class="w-100"></div>
                    <p class="mb-0 text-yup">{{ openInvoices }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!--/Faturas em aberto -->
          <!-- Fechamento de fatura -->
          <!-- <div class="card invoices-closing-card">
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-md-2"
                  style="display: flex; align-items: center"
                >
                  <div class="">
                    <span class="material-symbols-outlined icon_title">
                      receipt_long
                    </span>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="infos-desc">
                    <strong class="title">{{
                      $t('users.invoice-closing')
                    }}</strong>
                    <div class="w-100"></div>
                    <p class="mb-0 text-yup">12/10/2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!--/Fechamento de fatura -->
          <!-- Tipo de fatura -->
          <!-- <div class="card invoices-type-card">
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-md-2"
                  style="display: flex; align-items: center"
                >
                  <div class="">
                    <span class="material-icons icon_title"> star </span>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="infos-desc">
                    <strong class="title">{{
                      $t('users.invoice-type')
                    }}</strong>
                    <div class="w-100"></div>
                    <p class="mb-0 text-yup">Pré-pago</p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!--/Tipo de fatura -->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <usage-records-card />
        </div>
        <div class="col-lg-6">
          <invoices-card />
        </div>
        <div class="col-lg-6">
          <plans-card />
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header header_title">
              {{ $t('billing.prefs.header') }}
            </div>
            <div class="card-body">
              <p class="card-text">
                <b style="color: var(--gray-font-color-2)"
                  >Endereço de cobrança</b
                ><br /><span style="color: #9ca7b0"
                  >Av São João, 473 Loja 03<br />Centro, Peruíbe - SP<br />11750-000</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/rebranding/Card.vue';
import UsageRecordsCard from '@/components/rebranding/cards/UsageRecordsCard.vue';
import InvoicesCard from '@/components/rebranding/cards/InvoicesCard.vue';
import PlansCard from '@/components/rebranding/cards/PlansCard.vue';

export default {
  name: 'Billing',
  components: {
    PageHeader,
    Card,
    UsageRecordsCard,
    InvoicesCard,
    PlansCard,
  },
  data() {
    return {
      invoices: {},
      loaded: false,
      months: [],
      date: '',
      usage: [],
      openInvoices: 0,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD'),
        },
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {},
  methods: {
    // changeOpenInvoices(invoices) {
    //   invoices.forEach((invoice) => {
    //     console.log(invoice);
    //     if (invoice.status === 'pending') {
    //       this.openInvoices += 1;
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.bg-balance {
  background-color: #38e08c;
}
.card-deck .card:not(.card-deck .card.bg-balance) {
  /* flex: 0 0 20%; */
  flex: 1;
}

.invoices-card p {
  color: #521e66 !important;
}

.invoices-closing-card p,
.invoices-closing-card span {
  color: #6eda2c !important;
}

.invoices-type-card p,
.invoices-type-card span {
  color: #ffc000 !important;
}

.rebranding .card {
  height: 100%;
}

.col-lg-6 {
  margin-bottom: 2rem;
}

.infos-wrapper .card-deck .container-fluid,
.infos-wrapper .card-deck .container-fluid .row {
  height: 100%;
}

.infos-desc {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .card-deck .card {
    max-width: 23.333%;
  }
}

@media (max-width: 767.98px) {
  .card-deck {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1360px) and (max-width: 1600px) {
  .infos-desc {
    padding-left: 10px;
  }
  .card-deck .card {
    flex: 1 !important;
  }
}
</style>
